import {
  REMOVE_ITEM_FROM_WISHLIST_REQUEST,
  REMOVE_ITEM_FROM_WISHLIST_SUCCESS,
  REMOVE_ITEM_FROM_WISHLIST_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  message: "",
  error: null,
};

const removeFromWishlistReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REMOVE_ITEM_FROM_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        error: null,
      };
    case REMOVE_ITEM_FROM_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.message,
        error: null,
      };
    case REMOVE_ITEM_FROM_WISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: payload.error,
      };
    default:
      return state;
  }
};
export default removeFromWishlistReducer;
