import { combineReducers } from "redux";

import products from "./reducers/products/rootProductReducer";
import csrfToken from "./reducers/csrfTokenReducer";
import slider from "./reducers/sliderReducer";
import cart from "./reducers/cart/rootCartReducer";
import checkout from "./reducers/checkoutReducer";
import location from "./reducers/location/rootLocationReducers";
import aboutUs from "./reducers/settings/aboutUsReducer";
import termsAndPolicies from "./reducers/settings/termsAndPoliciesReducer";
import storeInfos from "./reducers/settings/storeInfosReducer";
import contactUs from "./reducers/contactUsReducer";
import auth from "./reducers/auth/rootAuthReducer";
import orders from "./reducers/orders/rootOrdersReducer";
import wishlist from "./reducers/wishlist/rootWishlistReducer";
import categories from "./reducers/categories/rootCategoriesReducer";
import filters from "./reducers/filtersReducer";
import features from "./reducers/featuresReducer";
import checkoutProduct from "./reducers/checkoutProductReducer";

export default combineReducers({
  products,
  csrfToken,
  slider,
  cart,
  checkout,
  location,
  aboutUs,
  termsAndPolicies,
  contactUs,
  auth,
  orders,
  storeInfos,
  wishlist,
  categories,
  filters,
  features, 
  checkoutProduct,
});
