import {
    ORDERS_REQUEST,
    ORDERS_SUCCESS,
    ORDERS_FAIL,
} from '../../../shared/constants';

const initialState = {
    loading: false,
    orders: [],
    error: null,
};


const ordersReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: payload.orders,
                error: null,
            };
        case ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                orders: [],
                error: payload.error,
            };
        default:
            return state;
    }
};
export default ordersReducer;