import {
  STORE_INFOS_REQUEST,
  STORE_INFOS_SUCCESS,
  STORE_INFOS_FAIL,
} from "../../../shared/constants";

import { api } from "../../../shared/shared";

const fetchStoreInfosBegin = () => ({
  type: STORE_INFOS_REQUEST,
});

const fetchStoreInfosSuccess = (store_infos) => ({
  type: STORE_INFOS_SUCCESS,
  payload: { store_infos },
});

const fetchStoreInfosFailure = (error) => ({
  type: STORE_INFOS_FAIL,
  payload: { error },
});
const fetchStoreInfosAction = () => (dispatch) => {
  dispatch(fetchStoreInfosBegin());
  dispatch(fetchStoreInfosSuccess(window.storeInfo));
};
export default fetchStoreInfosAction;
