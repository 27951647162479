import {
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  message: null,
  statusCode: 200,
  error: null,
};

const applyCouponReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case APPLY_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.message,
        statusCode: payload.statusCode,
        error: null,
      };
    case APPLY_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message,
        statusCode: payload.statusCode,
        error: payload.errors,
      };
    default:
      return state;
  }
};
export default applyCouponReducer;
