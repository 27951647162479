import {
  STORE_INFOS_REQUEST,
  STORE_INFOS_SUCCESS,
  STORE_INFOS_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  store_infos: null,
  error: null,
};

const storeInfosReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_INFOS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case STORE_INFOS_SUCCESS:
      return {
        ...state,
        loading: false,
        store_infos: payload.store_infos,
        error: null,
      };
    case STORE_INFOS_FAIL:
      return {
        ...state,
        loading: false,
        store_infos: null,
        error: payload.error,
      };
    default:
      return state;
  }
};
export default storeInfosReducer;
