import {
  UPDATE_QUANTITY_ITEM_CART_REQUEST,
  UPDATE_QUANTITY_ITEM_CART_SUCCESS,
  UPDATE_QUANTITY_ITEM_CART_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  message: "",
  error: null,
};

const updateQuantityItemCartReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_QUANTITY_ITEM_CART_REQUEST:
      return {
        ...state,
        loading: true,
        message: "",
        error: null,
      };
    case UPDATE_QUANTITY_ITEM_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.message,
        error: null,
      };
    case UPDATE_QUANTITY_ITEM_CART_FAIL:
      return {
        ...state,
        loading: false,
        message: "",
        error: payload.error,
      };
    default:
      return state;
  }
};
export default updateQuantityItemCartReducer;
