import {
  RELATED_PRODUCTS_FAIL,
  RELATED_PRODUCTS_REQUEST,
  RELATED_PRODUCTS_SUCCESS,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  items: [],
  error: null,
};

const relatedProductsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RELATED_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RELATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload.products,
        error: null,
      };
    case RELATED_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        items: [],
        error: payload.error,
      };
    default:
      return state;
  }
};
export default relatedProductsReducer;
