import { combineReducers } from "redux";

import register from "./registerReducer";
import login from "./loginReducer";
import authenticated from "./userReducer";
import forgotPassword from "./forgotPasswordReducer";
import resetPassword from "./resetPasswordReducer";

const rootAuthReducer = combineReducers({
  register,
  login,
  authenticated,
  forgotPassword,
  resetPassword
});
export default rootAuthReducer;
