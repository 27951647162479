import {
    ADD_TO_CARTPREORDER_REQUEST,
    ADD_TO_CARTPREORDER_SUCCESS,
    ADD_TO_CARTPREORDER_FAIL,
} from '../../../shared/constants';

const initialState = {
    loading: false,
    message: {},
    error: null 
};


const addToCartPreorderReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_TO_CARTPREORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_TO_CARTPREORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                items: payload.message,
                error: null,
            };
        case ADD_TO_CARTPREORDER_FAIL:
            return {
                ...state,
                loading: false,
                items: {},
                error: payload.error,
            };
        default:
            return state;
    }
};
export default addToCartPreorderReducer;