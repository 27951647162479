import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  USER_LOGOUT,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  user: null,
  error: null,
  isLogged: localStorage.getItem('isLogged') && true,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload.user,
        error: null,
        isLogged: true,
      };
    case USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        user: null,
        error: null,
        isLogged: false,
      };
    default:
      return state;
  }
};
export default userReducer;
