import {
    ORDERNOW_REQUEST,
    ORDERNOW_SUCCESS,
    ORDERNOW_FAIL,
} from '../../../shared/constants';

const initialState = {
    loading: false,
    message: {},
    error: null 
};


const orderNowReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ORDERNOW_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ORDERNOW_SUCCESS:
            return {
                ...state,
                loading: false,
                items: payload.message,
                error: null,
            };
        case ORDERNOW_FAIL:
            return {
                ...state,
                loading: false,
                items: {},
                error: payload.error,
            };
        default:
            return state;
    }
};
export default orderNowReducer;