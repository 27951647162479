import { FEATURES_REQUEST, FEATURES_SUCCESS, FEATURES_FAIL } from '../../shared/constants';

const initialState = {
    features: null,
    errors: null,
    loading: false
};

const featuresReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case FEATURES_REQUEST:
            return {
                ...state,
                errors: null,
                loading: true
            };
        case FEATURES_SUCCESS:
            return {
                ...state,
                features: payload.features,
                errors: null,
                loading: false
            };
        case FEATURES_FAIL:
            return {
                ...state,
                features: null,
                errors: payload.errors,
                loading: false
            };
        default:
            return state;
    }
};
export default featuresReducer;