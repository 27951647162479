import { combineReducers } from "redux";
import orders from "./ordersReducer";
import order from "./orderReducer";
import payment from "./paymentReducer";
import emailOrder from "./emailOrderReducer";

const rootOrdersReducer = combineReducers({
  orders,
  order,
  emailOrder,
  payment
});
export default rootOrdersReducer;
