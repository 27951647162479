import {
  FILTERS_REQUEST,
  FILTERS_SUCCESS,
  FILTERS_FAIL,
} from "../../shared/constants";

const initialState = {
  loading: false,
  filters: [],
  message: null
};

const filtersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: payload.filters,
      };
    case FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message,
        filters: []
      };
    default:
      return state;
  }
};
export default filtersReducer;
