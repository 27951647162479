import {
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAIL,
} from "../../shared/constants";

const initialState = {
  loading: false,
  message: null,
  statusCode: 200,
  error: null,
};

const ContactUsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.message,
        statusCode: payload.statusCode,
        error: null,
      };
    case CONTACT_US_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message,
        statusCode: payload.statusCode,
        error: payload.errors,
      };
    default:
      return state;
  }
};
export default ContactUsReducer;
