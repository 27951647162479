import { combineReducers } from "redux";
import list from "./wishlistReducer";
import add from "./addReducer";
import remove from "./removeItemReducer";

const rootWishlistReducer = combineReducers({
  list,
  add,
  remove,
});
export default rootWishlistReducer;
