import {
    PRODUCT_RATINGS_REQUEST,
    PRODUCT_RATINGS_SUCCESS,
    PRODUCT_RATINGS_FAIL,
} from '../../../../shared/constants';

const initialState = {
    loading: false,
    reviews_avg: 0,
    reviews_count: 0,
    reviews_counts: [],
    items: [],
    error: null,
    links: {},
    meta: {}
};


const ratingsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PRODUCT_RATINGS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case PRODUCT_RATINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews_avg: payload.data.reviews_avg,
                reviews_count: payload.data.reviews_count,
                reviews_counts: payload.data.reviews_counts,
                items: payload.data.reviews,
                links: payload.links,
                meta: payload.meta,
                error: null,
            };
        case PRODUCT_RATINGS_FAIL:
            return {
                ...state,
                loading: false,
                reviews_avg: 0,
                reviews_count: 0,
                reviews_counts: [],
                items: [],
                error: payload.error,
            };
        default:
            return state;
    }
};
export default ratingsReducer;