import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  product: {},
  error: "",
};

const productDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        product: {},
        error: "",
      };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        product: payload.product,
        error: "",
      };
    case PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        product: {},
        error: payload.error,
      };
    default:
      return state;
  }
};
export default productDetailsReducer;
