// ----------- CSRF TOKEN CONSTATS -----------
export const CSRF_TOKEN_REQUEST = "CSRF_TOKEN_REQUEST";
export const CSRF_TOKEN_SUCCESS = "CSRF_TOKEN_SUCCESS";
export const CSRF_TOKEN_FAIL = "CSRF_TOKEN_FAIL";

export const BEST_SELLING_PRODUCTS_REQUEST = "BEST_SELLING_PRODUCTS_REQUEST";
export const BEST_SELLING_PRODUCTS_SUCCESS = "BEST_SELLING_PRODUCTS_SUCCESS";
export const BEST_SELLING_PRODUCTS_FAIL = "BEST_SELLING_PRODUCTS_FAIL";

export const RELATED_PRODUCTS_REQUEST  = "RELATED_PRODUCTS_REQUEST";
export const RELATED_PRODUCTS_SUCCESS  = "RELATED_PRODUCTS_SUCCESS";
export const RELATED_PRODUCTS_FAIL     = "RELATED_PRODUCTS_FAIL";

export const NEW_PRODUCTS_REQUEST = "NEW_PRODUCTS_REQUEST";
export const NEW_PRODUCTS_SUCCESS = "NEW_PRODUCTS_SUCCESS";
export const NEW_PRODUCTS_FAIL = "NEW_PRODUCTS_FAIL";

export const FEATURED_PRODUCTS_REQUEST = "FEATURED_PRODUCTS_REQUEST";
export const FEATURED_PRODUCTS_SUCCESS = "FEATURED_PRODUCTS_SUCCESS";
export const FEATURED_PRODUCTS_FAIL = "FEATURED_PRODUCTS_FAIL";

export const TOP_RATED_PRODUCTS_REQUEST = "TOP_RATED_PRODUCTS_REQUEST";
export const TOP_RATED_PRODUCTS_SUCCESS = "TOP_RATED_PRODUCTS_SUCCESS";
export const TOP_RATED_PRODUCTS_FAIL = "TOP_RATED_PRODUCTS_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const FLASH_SALES_FAIL = "FLASH_SALES_FAIL";
export const FLASH_SALES_REQUEST = "FLASH_SALES_REQUEST";
export const FLASH_SALES_SUCCESS = "FLASH_SALES_SUCCESS";
// product by category consts
export const PRODUCTBYCATEGORY_REQUEST = "PRODUCTBYCATEGORY_REQUEST";
export const PRODUCTBYCATEGORY_SUCCESS = "PRODUCTBYCATEGORY_SUCCESS";
export const PRODUCTBYCATEGORY_FAIL = "PRODUCTBYCATEGORY_FAIL";

// auth consts
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// user consts
export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

//category consts
export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAIL = "CATEGORY_FAIL";

export const FEATURED_CATEGORIES_REQUEST = "FEATURED_CATEGORIES_REQUEST";
export const FEATURED_CATEGORIES_SUCCESS = "FEATURED_CATEGORIES_SUCCESS";
export const FEATURED_CATEGORIES_FAIL    = "FEATURED_CATEGORIES_FAIL";

//settings consts
export const SETTINGS_REQUEST = "SETTINGS_REQUEST";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";
export const SETTINGS_FAIL = "SETTINGS_FAIL";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";

//brands consts
export const BRANDS_REQUEST = "BRANDS_REQUEST";
export const BRANDS_SUCCESS = "BRANDS_SUCCESS";
export const BRANDS_FAIL = "BRANDS_FAIL";

// Coupon consts
export const COUPON_REQUEST = "COUPON_REQUEST";
export const COUPON_SUCCESS = "COUPON_SUCCESS";
export const COUPON_FAIL = "COUPON_FAIL";

// Contact Us consts
export const CONTACT_US_REQUEST = "CONTACT_US_REQUEST";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAIL = "CONTACT_US_FAIL";

// Checkout consts

export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_SUCCESS_CLEAR = "CHECKOUT_SUCCESS_CLEAR";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";

// Checkout Product
export const CHECKOUT_PRODUCT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_PRODUCT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_PRODUCT_FAIL = "CHECKOUT_FAIL";

// Apply Coupon

export const APPLY_COUPON_REQUEST = "APPLY_COUPON_REQUEST";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAIL = "APPLY_COUPON_FAIL";

export const REMOVE_COUPON_REQUEST = "REMOVE_COUPON_REQUEST";
export const REMOVE_COUPON_SUCCESS = "REMOVE_COUPON_SUCCESS";
export const REMOVE_COUPON_FAIL = "REMOVE_COUPON_FAIL";

// cart consts
export const GETCART_REQUEST = "GETCART_REQUEST";
export const GETCART_SUCCESS = "GETCART_SUCCESS";
export const GETCART_FAIL = "GETCART_FAIL";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";

export const ORDERNOW_REQUEST = "ORDERNOW_REQUEST";
export const ORDERNOW_SUCCESS = "ORDERNOW_SUCCESS";
export const ORDERNOW_FAIL = "ORDERNOW_FAIL";

export const ADD_TO_CARTPREORDER_REQUEST = "ADD_TO_CARTPREORDER_REQUEST";
export const ADD_TO_CARTPREORDER_SUCCESS = "ADD_TO_CARTPREORDER_SUCCESS";
export const ADD_TO_CARTPREORDER_FAIL = "ADD_TO_CARTPREORDER_FAIL";

export const REMOVE_ITEM_FROM_CART_REQUEST = "REMOVE_ITEM_FROM_CART_REQUEST";
export const REMOVE_ITEM_FROM_CART_SUCCESS = "REMOVE_ITEM_FROM_CART_SUCCESS";
export const REMOVE_ITEM_FROM_CART_FAIL = "REMOVE_ITEM_FROM_CART_FAIL";

export const REMOVE_ITEM_FROM_WISHLIST_REQUEST = "REMOVE_ITEM_FROM_WISHLIST_REQUEST";
export const REMOVE_ITEM_FROM_WISHLIST_SUCCESS = "REMOVE_ITEM_FROM_WISHLIST_SUCCESS";
export const REMOVE_ITEM_FROM_WISHLIST_FAIL    = "REMOVE_ITEM_FROM_WISHLIST_FAIL";

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAIL    = "ADD_TO_WISHLIST_FAIL";

export const DESTROY_CART_REQUEST = "DESTROY_CART_REQUEST";
export const DESTROY_CART_SUCCESS = "DESTROY_CART_SUCCESS";
export const DESTROY_CART_FAIL = "DESTROY_CART_FAIL";

export const UPDATE_QUANTITY_ITEM_CART_REQUEST =
  "UPDATE_QUANTITY_ITEM_CART_REQUEST";
export const UPDATE_QUANTITY_ITEM_CART_SUCCESS =
  "UPDATE_QUANTITY_ITEM_CART_SUCCESS";
export const UPDATE_QUANTITY_ITEM_CART_FAIL = "UPDATE_QUANTITY_ITEM_CART_FAIL";

// orders consts

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAIL = "ORDER_FAIL";

export const ORDERS_REQUEST = "ORDERS_REQUEST";
export const ORDERS_SUCCESS = "ORDERS_SUCCESS";
export const ORDERS_FAIL = "ORDERS_FAIL";

export const WISHLIST_REQUEST = "WISHLIST_REQUEST";
export const WISHLIST_SUCCESS = "WISHLIST_SUCCESS";
export const WISHLIST_FAIL = "WISHLIST_FAIL";

// states, cities and delivery fees

export const WILAYAS_REQUEST = "WILAYAS_REQUEST";
export const WILAYAS_SUCCESS = "WILAYAS_SUCCESS";
export const WILAYAS_FAIL = "WILAYAS_FAIL";

export const CITIES_REQUEST = "CITIES_REQUEST";
export const CITIES_SUCCESS = "CITIES_SUCCESS";
export const CITIES_FAIL = "CITIES_FAIL";

export const FEES_REQUEST = "FEES_REQUEST";
export const FEES_SUCCESS = "FEES_SUCCESS";
export const FEES_FAIL = "FEES_FAIL";

export const STATENAME_REQUEST = "STATENAME_REQUEST";
export const STATENAME_SUCCESS = "STATENAME_SUCCESS";
export const STATENAME_FAIL = "STATENAME_FAIL";

// slider consts

export const SLIDER_REQUEST = "SLIDER_REQUEST";
export const SLIDER_SUCCESS = "SLIDER_SUCCESS";
export const SLIDER_FAIL = "SLIDER_FAIL";

// search consts

export const SET_SEARCH = "SEARCH";

// testimonials consts

export const TESTIMONIALS_REQUEST = "TESTIMONIALS_REQUEST";
export const TESTIMONIALS_SUCCESS = "TESTIMONIALS_SUCCESS";
export const TESTIMONIALS_FAIL = "TESTIMONIALS_FAIL";

// about us consts

export const ABOUT_US_REQUEST = "ABOUT_US_REQUEST";
export const ABOUT_US_SUCCESS = "ABOUT_US_SUCCESS";
export const ABOUT_US_FAIL = "ABOUT_US_FAIL";

export const STORE_INFOS_REQUEST = "STORE_INFOS_REQUEST";
export const STORE_INFOS_SUCCESS = "STORE_INFOS_SUCCESS";
export const STORE_INFOS_FAIL = "STORE_INFOS_FAIL";

// terms and policies consts

export const TERMS_AND_POLICIES_REQUEST = "TERMS_AND_POLICIES_REQUEST";
export const TERMS_AND_POLICIES_SUCCESS = "TERMS_AND_POLICIES_SUCCESS";
export const TERMS_AND_POLICIES_FAIL = "TERMS_AND_POLICIES_FAIL";

// Shop cons
export const SHOP_REQUEST = "SHOP_REQUEST";
export const SHOP_SUCCESS = "SHOP_SUCCESS";
export const SHOP_FAIL = "SHOP_FAIL";


// Rating const
export const ADD_PRODUCT_RATING_REQUEST = "ADD_PRODUCT_RATING_REQUEST";
export const ADD_PRODUCT_RATING_SUCCESS = "ADD_PRODUCT_RATING_SUCCESS";
export const ADD_PRODUCT_RATING_FAIL = "ADD_PRODUCT_RATING_FAIL";

// Rating const
export const PRODUCT_RATINGS_REQUEST = "PRODUCT_RATINGS_REQUEST";
export const PRODUCT_RATINGS_SUCCESS = "PRODUCT_RATINGS_SUCCESS";
export const PRODUCT_RATINGS_FAIL = "PRODUCT_RATINGS_FAIL";

// Payment const
export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

// Rating const

export const FILTERS_REQUEST  = "FILTERS_REQUEST";
export const FILTERS_SUCCESS  = "FILTERS_SUCCESS";
export const FILTERS_FAIL     = "FILTERS_FAIL";

// Features
export const FEATURES_REQUEST = "FEATURES_REQUEST";
export const FEATURES_SUCCESS = "FEATURES_SUCCESS";
export const FEATURES_FAIL = "FEATURES_FAIL";

export const SHOW_ALERT_BEGIN = "SHOW_ALERT_BEGIN";
export const SHOW_ALERT_END = "SHOW_ALERT_END";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// Delivery Consts
export const DELIVERY_STOP_DESK = 1;
export const DELIVERY_DOMICILE = 0;

// Email const
export const EMAIL_REQUEST  = "EMAIL_REQUEST";
export const EMAIL_SUCCESS  = "EMAIL_SUCCESS";
export const EMAIL_FAIL     = "EMAIL_FAIL";
