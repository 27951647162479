import {
  WISHLIST_REQUEST,
  WISHLIST_SUCCESS,
  WISHLIST_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  products: [],
  error: null,
};

const wishlistReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload.products,
        error: null,
      };
    case WISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        products: [],
        error: payload.error,
      };
    default:
      return state;
  }
};
export default wishlistReducer;
