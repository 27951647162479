import {
    GETCART_REQUEST,
    GETCART_SUCCESS,
    GETCART_FAIL,
} from '../../../shared/constants';

const initialState = {
    loading: false,
    items: [],
    error: null 
};


const cartReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GETCART_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GETCART_SUCCESS:
            return {
                ...state,
                loading: false,
                items: payload.items,
                error: null,
            };
        case GETCART_FAIL:
            return {
                ...state,
                loading: false,
                items: [],
                error: payload.error,
            };
        default:
            return state;
    }
};
export default cartReducer;