import {
  EMAIL_REQUEST,
  EMAIL_SUCCESS,
  EMAIL_FAIL,
} from "../../../shared/constants";

const initialState = {
  statusCode: 200,
  message: "",
  errors: null,
  loading: false,
};

const emailOrderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case EMAIL_SUCCESS:
      return {
        ...state,
        message: payload.message,
        statusCode: payload.statusCode,
        errors: null,
        loading: false,
      };
    case EMAIL_FAIL:
      return {
        ...state,
        statusCode: payload.statusCode,
        message: payload.message,
        errors: payload.errors,
        loading: false,
      };
    default:
      return state;
  }
};
export default emailOrderReducer;
