import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
} from "../../../shared/constants";

const initialState = {
    loading: false,
    message: null,
    statusCode: 200,
    error: null,
};

const forgotPasswordReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload.message,
                statusCode: payload.statusCode,
                error: null,
            };
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                message: payload.message,
                statusCode: payload.statusCode,
                error: payload.errors,
            };
        default:
            return state;
    }
};
export default forgotPasswordReducer;
