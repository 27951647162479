import {
  FEATURED_CATEGORIES_REQUEST,
  FEATURED_CATEGORIES_SUCCESS,
  FEATURED_CATEGORIES_FAIL,
} from "../../../shared/constants";

import {api} from "../../../shared/shared"
 const fetchFeaturedCategoriesBegin = () => ({
  type: FEATURED_CATEGORIES_REQUEST,
});

 const fetchFeaturedCategoriesSuccess = (categories) => ({
  type: FEATURED_CATEGORIES_SUCCESS,
  payload: { categories },
});

const fetchFeaturedCategoriesFailure = (error) => ({
  type: FEATURED_CATEGORIES_FAIL,
  payload: { error },
});

export const fetchFeaturedCategoriesAction = () => (dispatch) => {
  {
    dispatch(fetchFeaturedCategoriesBegin());
    api
      .get("api/categories?featured=true")
      .then((res) => {
        if (res.data.success) {
          dispatch(fetchFeaturedCategoriesSuccess(res.data.data));
        } else {
          dispatch(fetchFeaturedCategoriesFailure(res.data.message));
        }
      })
      .catch((error) => {
        dispatch(fetchFeaturedCategoriesFailure(error.response.data));
      });
  }
};
